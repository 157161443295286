import { FaRocketchat } from "react-icons/fa"
import Intro from "../components/chat/Intro"
import ContactForm from "../components/chat/ContactForm"
import React, { useEffect, useState } from "react"
import chatIcon from "../images/VS_chat_icon.jpg" // Adjust the path according to your file structure

const VirtualAssistant = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalOpen = () => setIsModalOpen(true)
  const handleModalClose = () => setIsModalOpen(false)

  const handleFormSubmit = (formData) => {
    handleModalClose() // Close the modal after submitting the form
  }

  const handleChatToggle = () => {
    console.log('toggle')
    if (isModalOpen) {
      // If the chat modal is currently open, close it
      handleModalClose()
    } else {
      // Otherwise, open the chat modal
      handleModalOpen()
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      handleModalOpen()
    }, 30000) // Open the modal after 5 seconds

    return () => clearTimeout(timer) // Cleanup the timer on component unmount
  }, []) // Empty dependency array means this effect runs once on mount

  return (
    <div id="chatIconContainer" style={{
      position: "fixed",
      bottom: 50,
      right: 10,
      zIndex: 1000,
      padding: 10,
      backgroundColor: "white",
      borderRadius: "50%",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "60px",
      height: "60px",
      backgroundImage: `url(${chatIcon})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      marginRight: "20px"
    }}
         onClick={handleChatToggle}>
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent click events from bubbling up to the chat icon container
        }}
      >
      <Intro isOpen={isModalOpen} onClose={handleModalClose}>
        <ContactForm onSubmit={handleFormSubmit} />
      </Intro>
      </div>
    </div>
  )
}

export default VirtualAssistant
