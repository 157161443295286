import React, { useRef, useState, useEffect } from "react"
import { Box, Input, Button } from "@chakra-ui/core"
import { FaTimes, FaPaperPlane } from "react-icons/fa"
import DOMPurify from 'dompurify';
import topicsData from "../../data/topics.json"
import virtualAssistantIcon from "../../images/VS_Assistant_icon.jpg"
import chatButton from "../../images/button.png"
import { formatErrorDetails } from "gatsby/dist/query/utils"

const modalBackdropStyle = {
  marginTop: "120px",
  marginRight: "20px",
  marginBottom: "50px",
  position: "fixed",
  top: 0,
  right: 0,
  width: "500px",
  height: "100%",
  // backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1050,
}

const modalContentStyle = {
  marginTop: "120px",
  marginRight: "20px",
  marginBottom: "50px",
  position: "fixed",
  top: 0,
  right: 0,
  width: "500px",
  height: "85%",
  backgroundColor: "#F0F0F0",
  borderRadius: "10px 10px 10px 10px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  zIndex: 1051,
  display: "flex",
  flexDirection: "column",

}

const closeButtonStyle = {
  position: "absolute",
  top: "8px",
  right: "8px",
  cursor: "pointer",
  color: "grey",
  fontSize: "16px",
}

const headerStyle = {
  padding: "10px 20px",
  // borderBottom: "1px solid #ddd",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}

const chatBodyStyle = {
  flex: 1,
  padding: "20px",
  overflowY: "auto",
}

const chatFooterStyle = {
  padding: "10px 20px",
  // borderTop: "1px solid #ddd",
  display: "flex",
  alignItems: "center",
  // fontFamily: 'Gotham Book Italic, sans-serif',
  // fontStyle: 'italic',
}


const inputStyle = {
  fontFamily: "Gotham Book Italic, sans-serif",
  fontStyle: "italic",
  backgroundColor: "white",
}

const messageContainerStyle = {
  display: "flex",
  marginBottom: "10px",
  marginTop: "10px",
  alignItems: "flex-start", // Align items to flex-start
}

const messageTextStyle = {
  fontFamily: "Avenir Roman, sans-serif",
  padding: "10px",
  fontSize: "14px",
  fontWeight: "bold",
  color: "grey",
  marginBottom: "10px",
  borderRadius: "10px 0px 10px 10px", /* Rounded corners */

}

const userMessageTextStyle = {
  backgroundColor: "#d1e7dd",
  textAlign: "right",
  maxWidth: "70%",

}

const systemMessageTextStyle = {
  backgroundColor: "#f1f1f1",
}

const nameStyle = {
  color: "#666",
  fontSize: "12px",
}

const iconStyle = {
  width: "40px",
  height: "40px",
  marginRight: "10px",
  marginTop: "-10px",
}

const questionButtonStyle = {
  backgroundColor: "#39B54A",
  fontSize: "14px",
  margin: "2px",
  whiteSpace: "normal",
  textAlign: "left",
  maxWidth: "80%",
  padding: "10px",
  marginTop: "10px",
  fontFamily: "Avenir Roman, sans-serif",
  color: "white",
  marginLeft: "58px",
  height: '8%'
}

const bubble = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: "#fff", /* White background */
  borderRadius: "0px 10px 10px 10px", /* Rounded corners */
  padding: "8px 16px", /* Padding around the text */
  width: "100%", // Ensure bubble takes full width
  position: "relative",  // Added position: relative to allow for pseudo-element
  marginTop: "-17px", // Adjusted margin top to align with the assistant icon
  after: {
    content: " ",
    position: "absolute",
    bottom: "-8px",  // Adjust position of the tail
    left: "-5px",   // Adjust position of the tail
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "8px 8px 0 0",  // Defines the shape of the tail
    borderColor: "#fff",  // Color of the tail
    borderTopColor: "transparent",  // Make the top border transparent
  },
}

const bubbleUser = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  backgroundColor: "#d1e7dd",
  borderRadius: "10px 10px 10px 0px", /* Rounded corners */
  padding: "8px 16px", /* Padding around the text */
  width: "100%", // Ensure bubble takes full width
  position: "relative",  // Added position: relative to allow for pseudo-element
  marginTop: "-17px", // Adjusted margin top to align with the assistant icon
  after: {
    content: " ",
    position: "absolute",
    bottom: "-8px",  // Adjust position of the tail
    left: "-5px",   // Adjust position of the tail
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "8px 8px 0 0",  // Defines the shape of the tail
    borderColor: "#fff",  // Color of the tail
    borderTopColor: "transparent",  // Make the top border transparent
  },
}

const timeStyle = {
  fontSize: "12px",
  color: "#888",
  alignSelf: "flex-end", // Align time to the end of the flex container
  marginTop: "5px", // Add margin top for spacing
  marginLeft: "auto", // Move time to the rightmost side
}

const ChatModal = ({ isOpen, onClose, user, email, conversationId }) => {
  const [selectedTopic, setSelectedTopic] = useState("")
  const [questions, setQuestions] = useState([])
  const [history, setHistory] = useState([])
  const [userInput, setUserInput] = useState("")
  const [userInputCount, setUserInputCount] = useState(0)
  const [disableInput, setDisableInput] = useState(false)
  const chatBodyRef = useRef(null)
  const [showSatisfactionQuery, setShowSatisfactionQuery] = useState(false)

  const formatTime = (date) => {
    let hours = date.getHours()
    const minutes = date.getMinutes()
    const ampm = hours >= 12 ? "pm" : "am"
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    const strMinutes = minutes < 10 ? "0" + minutes : minutes
    console.log(`${hours}:${strMinutes} ${ampm}`)
    return `${hours}:${strMinutes} ${ampm}`
  }

  const currentTime = formatTime(new Date())

  const handleTopicClick = async (topic) => {
    if (disableInput || userInputCount >= 3) return;
    setSelectedTopic(topic)
    setQuestions(topicsData[topic])
    scrollToBottom()
  }

  const handleQuestionClick = async (index) => {
    if (disableInput || userInputCount >= 3) return;
    const question = questions[index].question
    const answer = questions[index].answer
    const currentTime = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })

    // Add user's question to the history
    setHistory((prevHistory) => [
      ...prevHistory,
      { question, answer: "", user: user || "User", time: currentTime },
    ])

    // Add Anna's answer to the history
    setTimeout(() => {
      setHistory((prevHistory) => [
        ...prevHistory,
        { question, answer, user: "Anna", time: currentTime },
      ])
    }, 500) // Small delay to simulate conversation flow

    scrollToBottom()
  }

  const handleUserInputSubmit = async () => {
    if (!userInput.trim() || disableInput) return

    const currentUserMessage = userInput.trim()
    const currentTime = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    setUserInput("")
    setHistory((prevHistory) => [
      ...prevHistory,
      { question: currentUserMessage, answer: "", user: user || "User", time: currentTime },
    ])

    // Simulate API call
    const response = await fetch(process.env.APP_API_URL + "/messages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ conversation_id: conversationId, body: currentUserMessage }),
    })

    const responseData = await response.json()
    const systemAnswer = responseData.data.answer

    setHistory((prevHistory) => [
      ...prevHistory,
      { question: currentUserMessage, answer: systemAnswer, user: "Anna", time: currentTime },
    ])

    setUserInputCount(userInputCount + 1)

    if (userInputCount >= 2 && systemAnswer === "Sorry, but I can't help you. Someone from VerifiSmart will contact you soon.") {
      await handleYesNoClick(false, true) // Simulate the user clicking "No"
    }

    if (userInputCount >= 1
      && (systemAnswer !== "I didn't understand you well. Could you please rephrase the question?"
        && systemAnswer !== "Sorry, but I can't help you. Someone from VerifiSmart will contact you soon.")) {
      setShowSatisfactionQuery(true)
      setDisableInput(true)
    }

    scrollToBottom()
  }

  const handleYesNoClick = async (isSatisfied, auto) => {
    setDisableInput(true)
    setShowSatisfactionQuery(false)

    if (!isSatisfied) {
      if (!auto) {
        const systemMessage = "Sorry to hear that. Someone from VerifiSmart will contact you soon."
        const currentTime = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })

        setHistory((prevHistory) => [
          ...prevHistory,
          { question: "No", answer: systemMessage, user: "Anna", time: currentTime },
        ])
      }

      const conversationData = {
        conversationId: conversationId,
        messages: history.map(entry => ({
          question: entry.question,
          answer: entry.answer,
          user: entry.user,
          time: entry.time,
        })),
      }


      const allMessages = conversationData.messages
        .filter((_, index) => index % 2 !== 0) // Select only every second entry
        .map((message, index) => {
          const answerWithoutLinks = message.answer.replace(/<a[^>]*>|<\/a>/g, ""); // Remove <a href> tags
          return `${index + 1}. Question: ${message.question}, Answer: ${answerWithoutLinks}`;
        });

      const formattedMessages = allMessages.join("<br>");

      const response = await fetch(process.env.APP_API_URL + "/contactforms", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          origin: "virtual-assistant",
          name: user,
          email: email,
          body: formattedMessages,
        }),
      })

      scrollToBottom()
    } else {
      const systemMessage = "I'm glad that I managed to help you. If you have any additional questions, please use our contact form, and someone will reach you as soon as possible."
      const currentTime = new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })

      setHistory((prevHistory) => [
        ...prevHistory,
        { question: "No", answer: systemMessage, user: "Anna", time: currentTime },
      ])

      scrollToBottom()
    }
  }

  const scrollToBottom = () => {
    const timer = setTimeout(() => {
      if (chatBodyRef.current) {
        chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight
      }
    }, 100)

    return () => clearTimeout(timer)
  }

  useEffect(() => {
    if (isOpen) {
      scrollToBottom()
      document.body.style.overflow = "hidden" // Disable scrolling on the body when the modal is open
    } else {
      document.body.style.overflow = "auto" // Enable scrolling on the body when the modal is closed
    }

    // Cleanup function to reset body overflow on component unmount
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [history, isOpen])

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleUserInputSubmit()
    }
  }

  if (!isOpen) return null

  return (
    <div style={modalBackdropStyle}>
      <div style={modalContentStyle}>
        <Box style={headerStyle} ref={chatBodyRef}>
          {/*<h2>Chat with Anna</h2>*/}
          <FaTimes size={16} color="black" onClick={onClose} style={closeButtonStyle} />
        </Box>
        <Box style={chatBodyStyle} ref={chatBodyRef}>
          <Box style={{ ...messageContainerStyle, justifyContent: "flex-start" }}>
            <img src={virtualAssistantIcon} alt="Assistant Icon" style={iconStyle} />
            <Box style={{ ...messageTextStyle, ...systemMessageTextStyle }}>
              <div style={bubble}>
                <p>Hi, I'm Anna, the virtual assistant of VerifiSmart!</p>
                <span style={timeStyle}>{formatTime(new Date())}</span>
              </div>
            </Box>
          </Box>
          <Box style={{ ...messageContainerStyle, justifyContent: "flex-start" }}>
            <img src={virtualAssistantIcon} alt="Assistant Icon" style={iconStyle} />
            <Box style={{ ...messageTextStyle, ...systemMessageTextStyle }}>
              <div style={bubble}>
                <p>Please tell me how can I help you?</p>
                <span style={timeStyle}>{formatTime(new Date())}</span>
              </div>
            </Box>
          </Box>
          <Box style={{ ...messageContainerStyle, justifyContent: "flex-start" }}>
            <img src={virtualAssistantIcon} alt="Assistant Icon" style={iconStyle} />
            <Box style={{ ...messageTextStyle, ...systemMessageTextStyle }}>
              <div style={bubble}>
                <p>Or simply choose one of the most common topics:</p>
                <span style={timeStyle}>{formatTime(new Date())}</span>
              </div>
            </Box>
          </Box>
          <Box style={{ marginLeft: "58px" }}>

            {Object.keys(topicsData).map((topic, index) => (
              <Button size={"sm"} key={index} onClick={() => handleTopicClick(topic)}
                      style={{ backgroundColor: "#39B54A", color: "white", margin: "2px" }}>
                {topic}
              </Button>
            ))}
          </Box>
          {selectedTopic && (
            <>
              <Box style={{ ...messageContainerStyle, justifyContent: "flex-end" }}>
                <Box style={{ ...messageContainerStyle, justifyContent: "flex-end" }}>
                  <Box style={{ ...messageTextStyle, ...userMessageTextStyle }}>
                    <p>{selectedTopic}</p>
                    <span style={timeStyle}>{currentTime}</span>
                  </Box>
                </Box>
              </Box>
              {questions.map((question, index) => (
                <Button  key={index} onClick={() => handleQuestionClick(index)} style={questionButtonStyle}>
                  {question.question}
                </Button>
              ))}
            </>
          )}
          {history.map((entry, index) => (
            <React.Fragment key={index}>
              {entry.user === (user || "User") ? (
                <Box style={{ ...messageContainerStyle, justifyContent: "flex-end" }}>
                  <Box style={{ ...messageTextStyle, ...userMessageTextStyle }}>
                    <p>{entry.question}</p>
                    <span style={timeStyle}>{currentTime}</span>
                  </Box>
                </Box>
              ) : (
                <Box style={{ ...messageContainerStyle, justifyContent: "flex-start" }}>
                  <img src={virtualAssistantIcon} alt="Assistant Icon" style={iconStyle} />
                  <Box style={{ ...messageTextStyle, ...systemMessageTextStyle }}>
                    <div style={bubble}>
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(entry.answer) }} />
                      <span style={timeStyle}>{currentTime}</span>
                    </div>
                  </Box>
                </Box>
              )}
            </React.Fragment>
          ))}
          {showSatisfactionQuery && (
            <>
              <Box style={{ ...messageContainerStyle, justifyContent: "flex-start" }}>
                <img src={virtualAssistantIcon} alt="Assistant Icon" style={iconStyle} />
                <Box style={{ ...messageTextStyle, ...systemMessageTextStyle }}>
                  <div style={bubble}>
                    <p>Are you satisfied with the answers?</p>
                    <span style={timeStyle}>{currentTime}</span>
                  </div>
                  <div style={{ marginTop: "10px"}}>
                    <Button onClick={() => handleYesNoClick(true, false)} style={{ marginRight: "10px", borderRadius: "5px" }}>Yes</Button>
                    <Button onClick={() => handleYesNoClick(false, false)} style={{borderRadius: "5px"}}>No</Button>
                  </div>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box style={chatFooterStyle}>
          <Input
            style={inputStyle}
            placeholder="Write a message"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyPress={handleKeyPress}
            flex={1}
            disabled={disableInput || userInputCount >= 3}
          />

          <Button ml={2} onClick={handleUserInputSubmit} disabled={disableInput} style={{ backgroundImage: `url(${chatButton})`, backgroundPosition: "center" }}>
            {/*<img src={chatButton} alt="Assistant Icon" style={iconStyle} />*/}
          </Button>
        </Box>
      </div>
    </div>
  )
}


export default ChatModal
