import React, { useEffect, useRef, useState } from 'react';
import { Box, Flex, Input, Button } from '@chakra-ui/core';
import { FaTimes } from 'react-icons/fa';
import ChatModal from './ChatModal';
import virtualAssistantIcon from "../../images/VS_Assistant_icon.jpg"

const modalBackdropStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1050,
};

const modalContentStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#F0F0F0',
  padding: '16px',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  zIndex: 1051,
  width: '400px',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '8px',
  right: '8px',
  cursor: 'pointer',
  color: 'grey',
  fontSize: '16px',
};

const iconStyle = {
  width: '40px',
  height: '40px',
  marginRight: '10px',
  marginTop: '-10px',
};

const headerStyle = {
  fontFamily: 'Avenir Roman, sans-serif',
  fontSize: '14px',
  marginBottom: '4px',
  fontWeight: 'bold',
  color: 'grey'
};

const subHeaderStyle = {
  fontFamily: 'Avenir Roman, sans-serif',
  fontSize: '14px',
  marginBottom: '8px',
  fontWeight: 'bold',
  color: 'grey'
};

const inputStyle = {
  fontFamily: 'Gotham Book Italic, sans-serif',
  fontStyle: 'italic',
  marginBottom: '8px',
  backgroundColor: 'white'
};

const buttonStyle = {
  backgroundColor: '#4CAF50',
  color: 'white',
  width: 'auto',
  padding: '6px 14px',
  float: 'right',
  height: '80%'
};

const Intro = ({ isOpen, onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [conversationId, setConversationId] = useState('');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      console.log('idemo')

      onClose();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;
    if (!name) {
      setNameError(true);
      valid = false;
    } else {
      setNameError(false);
    }
    if (!email || !isValidEmail(email)) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }
    if (valid) {
      const response = await fetch(process.env.APP_API_URL + '/conversations', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: name, email: email }),
      });

      if (response.status === 201) {
        const responseData = await response.json();
        setConversationId(responseData.data.id)
        setIsChatOpen(true); // Set chat open
        onClose(); // Close the intro modal
      }
    }
  };

  if (!isOpen && !isChatOpen) return null; // Render nothing if not open

  return (
    <>
      {isOpen && !isChatOpen && ( // Render intro only if it's open and chat isn't started
        <div style={modalBackdropStyle} ref={ref}>
          <div style={modalContentStyle}>
            <FaTimes size={16} onClick={onClose} style={closeButtonStyle} />
            <Flex direction="column" alignItems="center">
              <Flex alignItems="center" mb="2" width="100%">
                <img src={virtualAssistantIcon} alt="Assistant Icon" style={iconStyle} />
                <Box textAlign="left">
                  <Box style={headerStyle}>Hi! I'm Anna.</Box>
                  <Box style={subHeaderStyle}>I'm here to answer your questions</Box>
                </Box>
              </Flex>
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box>
                  <Input
                    placeholder="Your name"
                    style={inputStyle}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError(false);
                    }}
                    isRequired
                    borderColor={nameError ? 'red.500' : 'gray.200'}
                  />
                  <Input
                    type="email"
                    placeholder="Your e-mail"
                    style={inputStyle}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError(false);
                    }}
                    isRequired
                    borderColor={emailError ? 'red.500' : 'gray.200'}
                  />
                </Box>
                <Button type="submit" style={buttonStyle}>
                  Start chat
                </Button>
              </form>
            </Flex>
          </div>
        </div>
      )}
      {isChatOpen && (
        <ChatModal
          isOpen={isChatOpen}
          onClose={() => {
            setIsChatOpen(false);
            setName('');
            setEmail('');
          }}
          user={name}
          email={email}
          conversationId={conversationId}
        />
      )}
    </>
  );
};

export default Intro;
